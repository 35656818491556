// role.directive.ts
import {Directive, Input, ElementRef, Renderer2, OnInit, ViewContainerRef, TemplateRef} from '@angular/core';
import {AuthService} from "../services/auth.service";

@Directive({
  standalone: true,
  selector: '[appRole]'
})
export class RoleDirective {
  @Input('appRole') allowedRoles: string[];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService // Inject your authentication service
  ) {
  }

  public hasView = false;

  @Input() set appRole(condition: string []) {
    if (!this.authService.loggedIn()) {
      this.hasView = false;
      this.viewContainerRef.clear();
      return;
    }

    let intersection = this.authService.getUserRoles().filter((x: string) => condition.includes(x.toLowerCase()));
    if (intersection.length > 0 || condition[0] == '*') {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (condition && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }


}
